import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layouts'
import BlogPosts from '../components/BlogPosts'

// Query for the posts content in Prismic
export const query = graphql`
{
  allPrismicPost(sort: {fields: data___date, order: DESC}){
    edges{
      node{
        id
        uid
        type
        data {
          title {
            html
            text
            raw
          }
          date
          body{
            __typename
            ... on PrismicPostBodyText{
              slice_type
              slice_label
              primary{
                text {
                  html
                  text
                  raw
                }
              }
            }
            ... on PrismicPostBodyImageWithCaption{
              slice_type
              slice_label
              primary{
                image {
                  alt
                  copyright
                  url
                }
                caption {
                  html
                  text
                  raw
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export default ({ data }) => {
  // Define the Blog Post content returned from Prismic
  const posts = data.allPrismicPost.edges;

  return(
    <Layout title="News">
      <BlogPosts posts={ posts }/>
    </Layout>
  )
}
