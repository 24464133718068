import React from 'react'
import { Link } from "gatsby"
import { Date } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import NewsSidebar from './NewsSidebar'

// Function to retrieve a small preview of the post's text
const firstParagraph = (post => {
  // Find the first text slice of post's body
  let firstTextSlice = post.data.body.find(s => s.slice_type === 'text');
  if (firstTextSlice != null) {
    // Set the character limit for the text we'll show in the homepage
    const textLimit = 300
    let text = firstTextSlice.primary.text.text
    let limitedText = text.substring(0, textLimit)

    if (text.length > textLimit) {
      // Cut only up to the last word and attach '...' for readability
      return (
        <p>{ limitedText.substring(0, limitedText.lastIndexOf(' ')) + '...' }</p>
      );
    } else {
      // If it's shorter than the limit, just show it normally
      return <p>{ text }</p>;
    }
  } else {
    // If there are no slices of type 'text', return nothing
    return null;
  }
})

// A summary of the Blog Post
const PostSummary = ({ post }) => {
  
  // Store and format the blog post's publication date
  let postDate = Date(post.data.date);
  postDate = postDate ? 
    new Intl.DateTimeFormat('en-US', {
      month: 'short', 
      day: '2-digit', 
      year: 'numeric'
    }).format(postDate) :
    '';
  
  return (
    <div className="post-summary" key={ post.id } >
      <h2>
        {/* We render a link to a particular post using the linkResolver for the url and its title */}
        <Link to={ linkResolver(post) }>
          { post.data.title.text }
        </Link>
      </h2>
      <p className="blog-post-meta">
        <time>{ postDate }</time>
      </p>
      {/* Renders a small preview of the post's text */}
      { firstParagraph(post) }
    </div>
  );
}

export default ({ posts }) => {
  if(!posts) return null;

  return(
    <div class="pure-g">
      <NewsSidebar posts={posts} />
      <div class="pure-u-lg-3-5 pure-u-1-1 main">
        {posts.map((post) => {
          return <PostSummary post={ post.node } key={ post.node.id }/>
        })}
      </div>
    </div>
  )
}
